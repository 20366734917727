import styled from "styled-components";

export const StyledCarousel = styled.div`
  margin: 0 auto;
  background-color: #EFFFFA;
  /* height: 50vh; */
  img {
    opacity: 100%;
    object-fit: contain;
  }

`;
